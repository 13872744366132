import Aside from "../components/Aside";
import CardJob from "../components/CardJob";
import { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
// import NavBar from "../components/Navbar";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchJob,
  fetchPosterCategory,
  fetchVideos,
  fetchVideoCategory,
  fetchArticleCategory,
  fetchArticle,
} from "../store/action";
import NavBar from "../components/Navbar";
import ReactPlayer from "react-player";
import VideoThumbnail from "react-video-thumbnail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { faPlayCircle } from "@fortawesome/free-regular-svg-icons";
import Footer from "../components/Footer";
const Article = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { articles, article, category, loading, error } = useSelector(
    (state) => state.article
  );
  const [selectedVideo, setselectedVideo] = useState(false);
  const [modalVideo, setmodalVideo] = useState(false);
  const [selectImage, setselectImage] = useState("");
  const [modalImage, setmodalImage] = useState(false);
  const [selecetCategory, setselecetCategory] = useState("All");
  const [filter, setfilter] = useState(false);

  useEffect(() => {
    setfilter(articles);
  }, [articles]);
  useEffect(() => {
    if (selecetCategory === "All") {
      const groupedJobs = articles.reduce((result, job, index) => {
        if (index % 3 === 0) {
          result.push([job]);
        } else {
          result[result.length - 1].push(job);
        }
        return result;
      }, []);
      // setimages(groupedJobs);
      setfilter(...groupedJobs);
      console.log(...groupedJobs, "dsakdpsakp");
    } else {
      let res = articles.filter(
        (e) => e.idcategoryarticle === Number(selecetCategory)
      );
      console.log(res, "ini res");
      console.log(articles, "ini jos");
      // console.log(res, "ini res");
      const groupedJobs = res.reduce((result, job, index) => {
        if (index % 3 === 0) {
          result.push([job]);
        } else {
          result[result.length - 1].push(job);
        }
        return result;
      }, []);
      // setimages(groupedJobs);
      setfilter(...groupedJobs);
      console.log(...groupedJobs, "dsakdpsakp");
    }
  }, [selecetCategory]);

  // console.log(selecetCategory, "category seke");
  // const [datajob, setdatajob] = useState(video);

  const onClose = () => {
    setmodalImage(false);
    setselectImage("");
  };
  useEffect(() => {
    dispatch(fetchArticle());
    dispatch(fetchArticleCategory());
  }, []);
  // console.log(video, "ini job");
  // console.log(category, "cate");
  // if (loading) {
  //   return <h1>loading....</h1>;
  // }
  // const thumnail = (url, size) => {
  //   let results;
  //   let video;
  //   if (url === null) {
  //     return "";
  //   }
  //   size = size === null ? "big" : size;
  //   results = url.match("[\\?&]v=([^&#]*)");
  //   video = results === null ? url : results[1];

  //   if (size === "small") {
  //     return "http://img.youtube.com/vi/" + video + "/2.jpg";
  //   }
  //   console.log("http://img.youtube.com/vi/" + video + "/0.jpg");
  //   return "http://img.youtube.com/vi/" + video + "/0.jpg";
  // };
  // const getThumb = function (url, size) {
  //   if (url === null) {
  //     return "";
  //   }
  //   size = size === null ? "big" : size;
  //   results = url.match("[\\?&]v=([^&#]*)");
  //   video = results === null ? url : results[1];

  //   if (size === "small") {
  //     return "http://img.youtube.com/vi/" + video + "/2.jpg";
  //   }
  //   return "http://img.youtube.com/vi/" + video + "/0.jpg";
  // };
  // console.log(articles, "ihzaaa");
  return (
    <>
      {modalImage && (
        <div
          onClick={onClose}
          className="fixed bg-black bg-opacity-50 backdrop-blur-sm w-full h-full z-50"
        >
          <div className="flex h-full justify-center items-center">
            <div className="w-96 md:scale-125">
              <img class=" object-cover rounded-lg" src={selectImage} alt="" />
            </div>
          </div>
        </div>
      )}
      <NavBar />
      <div className="relative w-full bg-blue-1001 md:px-40 md:p-10 pl-14 p-4 z-30">
        <p className="font-light text-5xl text-white">Articles</p>
      </div>
      <div className="bg-red"></div>
      <div className="relative mt-12">
        <div className=" relative z-30 w-full bg-white  px-28 py-5">
          <div className="flex flex-row justify-center gap-2 flex-wrap">
            <div
              onClick={() => {
                setselecetCategory("All");
              }}
              className="cursor-pointer hover:scale-105 border bg-black text-white p-2 px-4 rounded-md"
            >
              <p>All</p>
            </div>

            {category.length !== 0 &&
              category.map((e) => {
                return (
                  <div
                    onClick={() => {
                      setselecetCategory(e.id);
                    }}
                    className="cursor-pointer hover:scale-105 border bg-black text-white p-2 rounded-md"
                  >
                    <p>{e.name}</p>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="mx-auto px-4 sm:px-6 flex flex-row gap-10 flex-wrap justify-center">
          {!loading &&
            filter &&
            filter.map((item) => {
              return (
                <>
                  <div
                    onClick={() => {
                      navigation(`/artikelDetail/${item.id}`, {
                        state: item,
                      });
                      // return;
                      // setmodalVideo(true);
                      // setselectedVideo(video?.video_url);
                    }}
                    className=" relative cursor-pointer flex  items-start flex-col w-72"
                  >
                    <img
                      src={item?.image_url}
                      alt=""
                      className="w-full h-40 object-cover"
                    />
                    <div className="flex flex-col justify-start text-left items-start  w-full py-4 space-y-3">
                      <p className="font-bold text-lg">{item?.title}</p>
                      <p className="font-semibold text-base">
                        {item?.subTitle}{" "}
                      </p>
                      <p className="text-sm">{item?.source}</p>
                    </div>
                    {/* <div className="absolute bottom-0 bg-red-300 w-full p-5">
                      ihza
                    </div> */}
                  </div>
                </>
              );
            })}
          {!filter && <div className="my-12">No Article Found</div>}
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Article;
