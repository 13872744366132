import logo from "../images/fi2.png";
import tiktok from "../images/pngwing.com.png";
import kampus_merdeka from "../images/kampus_merdeka.png";
import logo_sipedli from "../images/logo_sipedli.png";
import pkmlogo from "../images/pkmlogo.png";
import stmik from "../images/stmik.png";
import tutwuri from "../images/tutwuri.png";
const Footer = () => {
  return (
    <>
      <div className="bg-slate-200 w-full p-16 flex justify-center flex-col">
        <div className="flex justify-center ">
          <p className="text-2xl md:text-3xl font-bold mb-16 text-slate-500 text-center">
            DI DUKUNG OLEH
          </p>
        </div>
        <div className="flex flex-col md:flex-row md:space-x-16 space-y-8 justify-center">
          <div className="flex flex-row space-x-16 justify-center items-baseline">
            <img
              src={tutwuri}
              className="w-20 h-20 md:w-40 md:h-40 grayscale hover:grayscale-0 transition delay-100 hover:scale-105 "
              alt=""
            />
            <img
              src={kampus_merdeka}
              className="w-24 h-14 md:w-52 md:h-32 grayscale hover:grayscale-0 transition delay-100 hover:scale-105"
              alt=""
            />
          </div>
          <div className="flex flex-row justify-center space-x-16 items-stretch">
            <img
              src={pkmlogo}
              className="w-32 h-16 md:w-52 md:h-32 grayscale hover:grayscale-0 transition delay-100 hover:scale-105 "
              alt=""
            />
            <img
              src={stmik}
              className="w-20 h-20 md:w-40 md:h-40 grayscale hover:grayscale-0 transition delay-100 hover:scale-105 md:-translate-y-8 "
              alt=""
            />
          </div>
        </div>
      </div>
      <footer className="bg-blue-1001 flex flex-col-reverse md:flex-row p-16">
        {/* kiri */}
        <div className="text-white w-full  md:w-1/2 pr-10 space-y-5">
          {/* <img className="w-32" src="" alt="" /> */}
          <img src={logo} className="w-72" alt="" />
          <p className="font-bold text-lg">SIPEDLI</p>
          <p className="font-light text-md text-justify">
            Selamat datang di SIPEDLI, Sistem Informasi Digital Literasi yang
            memberikan akses mudah dan terpercaya untuk meningkatkan pengetahuan
            dan keterampilan digital Anda. Kami berkomitmen untuk mendukung
            perkembangan literasi digital di era teknologi modern ini. Temukan
            berbagai sumber daya, pelatihan, dan konten informatif yang
            dirancang untuk membantu Anda memahami dan menguasai dunia digital.
          </p>
          <p>pkmpmsipedli23@gmail.com</p>
          <img src="image.png" alt="" />
          <p>© PKM PM - STMIK DCI</p>
        </div>
        {/* kanan */}
        <div className="text-white flex-col justify-center w-full md:w-1/2 space-y-7 items-center ">
          <div className="text-center">
            <p className="text-3xl font-bold">Media Sosial</p>
          </div>
          <div className="flex flex-row items-center justify-center">
            {/* <div>
              <ul className="flex flex-col cursor-pointer mr-12">
                <li>
                  <a href="pkmpmsipedli23@gmail.com">
                    <img
                      className="w-32 md:w-12"
                      src="https://cdn4.iconfinder.com/data/icons/social-media-logos-6/512/112-gmail_email_mail-512.png"
                      alt=""
                    />
                  </a>
                </li>
              </ul>
            </div> */}
            <div>
              <ul className="flex flex-col cursor-pointer ">
                <li>
                  <a href="https://instagram.com/sipedli?igshid=NzZhOTFlYzFmZQ==">
                    <img
                      className="w-20 md:w-10"
                      src="https://fh.unwir.ac.id/wp-content/uploads/2018/03/instagram-logo-png-transparent-background-hd-3.png"
                      alt=""
                    />
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <ul className="flex flex-col cursor-pointer ">
                <a href="https://youtube.com/@SIPEDLI?feature=shared">
                  <img
                    className="w-60 md:w-40"
                    src="https://www.freepnglogos.com/uploads/youtube-logo-hd-8.png"
                    alt=""
                  />
                </a>
              </ul>
            </div>
            <div>
              <ul className="flex flex-col cursor-pointer ">
                <a href="https://www.tiktok.com/@pkmpmsipedli2023?_t=8g8cE6hTvm6&_r=1">
                  <img className="w-16 md:w-10" src={tiktok} alt="" />{" "}
                </a>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
