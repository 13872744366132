import Aside from "../components/Aside";
import CardJob from "../components/CardJob";
import { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
// import NavBar from "../components/Navbar";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchJob,
  fetchPosterCategory,
  fetchVideos,
  fetchVideoCategory,
} from "../store/action";
import NavBar from "../components/Navbar";
import ReactPlayer from "react-player";
import VideoThumbnail from "react-video-thumbnail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { faPlayCircle } from "@fortawesome/free-regular-svg-icons";
import Footer from "../components/Footer";
const Video = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { video, category, loading, error } = useSelector(
    (state) => state.video
  );
  const [selectedVideo, setselectedVideo] = useState(false);
  const [modalVideo, setmodalVideo] = useState(false);
  const [selectImage, setselectImage] = useState("");
  const [modalImage, setmodalImage] = useState(false);
  const [selecetCategory, setselecetCategory] = useState("All");
  const [filter, setfilter] = useState(false);
  console.log(filter, "flter");

  useEffect(() => {
    setfilter(video);
  }, [video]);

  const onClose = () => {
    setmodalImage(false);
    setselectImage("");
  };
  useEffect(() => {
    dispatch(fetchVideos());
    dispatch(fetchVideoCategory());
  }, []);
  // console.log(video, "ini job");
  // console.log(category, "cate");
  // if (loading) {
  //   return <h1>loading....</h1>;
  // }
  const thumnail = (url, size) => {
    let results;
    let video;
    if (url === null) {
      return "";
    }
    size = size === null ? "big" : size;
    results = url.match("[\\?&]v=([^&#]*)");
    video = results === null ? url : results[1];

    if (size === "small") {
      return "http://img.youtube.com/vi/" + video + "/2.jpg";
    }
    console.log("http://img.youtube.com/vi/" + video + "/0.jpg");
    return "http://img.youtube.com/vi/" + video + "/0.jpg";
  };
  // const getThumb = function (url, size) {
  //   if (url === null) {
  //     return "";
  //   }
  //   size = size === null ? "big" : size;
  //   results = url.match("[\\?&]v=([^&#]*)");
  //   video = results === null ? url : results[1];

  //   if (size === "small") {
  //     return "http://img.youtube.com/vi/" + video + "/2.jpg";
  //   }
  //   return "http://img.youtube.com/vi/" + video + "/0.jpg";
  // };
  useEffect(() => {
    if (selecetCategory === "All") {
      const groupedJobs = video.reduce((result, job, index) => {
        if (index % 3 === 0) {
          result.push([job]);
        } else {
          result[result.length - 1].push(job);
        }
        return result;
      }, []);
      // setimages(groupedJobs);
      setfilter(...groupedJobs);
      console.log(...groupedJobs, "dsakdpsakp");
    } else {
      let res = video.filter(
        (e) => e.idcategoryvideo === Number(selecetCategory)
      );
      console.log(res, "ini res");
      console.log(video, "ini jos");
      // console.log(res, "ini res");
      const groupedJobs = res.reduce((result, job, index) => {
        if (index % 3 === 0) {
          result.push([job]);
        } else {
          result[result.length - 1].push(job);
        }
        return result;
      }, []);
      // setimages(groupedJobs);
      setfilter(...groupedJobs);
      console.log(...groupedJobs, "dsakdpsakp");
    }
  }, [selecetCategory]);
  return (
    <>
      {modalImage && (
        <div
          onClick={onClose}
          className="fixed bg-black bg-opacity-50 backdrop-blur-sm w-full h-full z-50"
        >
          <div className="flex h-full justify-center items-center">
            <div className="w-96 md:scale-125">
              <img class=" object-cover rounded-lg" src={selectImage} alt="" />
            </div>
          </div>
        </div>
      )}
      <NavBar />
      <div className="relative w-full bg-blue-1001 md:px-40 md:p-10 pl-14 p-4 z-30">
        <p className="font-light text-5xl text-white">Video</p>
      </div>
      <div className="bg-red"></div>
      <div className="relative mb-10">
        <div className=" relative z-30 w-full bg-white  px-28 py-5">
          <div className="flex flex-row justify-center gap-2 flex-wrap">
            <div
              onClick={() => {
                setselecetCategory("All");
              }}
              className="cursor-pointer hover:scale-105 border bg-black text-white p-2 px-4 rounded-md"
            >
              <p>All</p>
            </div>

            {category.length !== 0 &&
              category.map((e) => {
                return (
                  <div
                    onClick={() => {
                      setselecetCategory(e.id);
                      // handleklikCategory(e.id)
                    }}
                    className="cursor-pointer hover:scale-105 border bg-black text-white p-2 rounded-md"
                  >
                    <p>{e.name}</p>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="mx-auto px-4 sm:px-6 flex flex-row gap-10 flex-wrap justify-center">
          {!loading &&
            filter &&
            filter.map((video) => {
              return (
                <>
                  <div
                    onClick={() => {
                      // return;
                      setmodalVideo(true);
                      setselectedVideo(video?.video_url);
                    }}
                    className="relative cursor-pointer flex justify-center items-center"
                  >
                    <img src={thumnail(video?.video_url, "big")} alt="" />
                    <div className="absolute">
                      <FontAwesomeIcon
                        className="text-red-500"
                        icon={faPlayCircle}
                        size="5x"
                      />
                    </div>
                  </div>
                </>
              );
            })}
          {!filter && <div className="my-12">No Video Found</div>}
        </div>
      </div>
      {modalVideo && (
        <>
          <div className="justify-center items-center flex fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative  my-6 mx-auto w-full md:w-5/12">
              {/*content*/}
              <div className="border-0 shadow-lg relative flex flex-col w-full  outline-none focus:outline-none">
                {/*header*/}
                {/* <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    Form Input Category Poster
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => {
                      setmodalVideo(false);
                      setselectedVideo(false);
                    }}
                  >
                    <svg
                      className="w-6 h-6"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div> */}
                {/*body*/}
                <div className="flex h-96 w-full flex-col relative">
                  <ReactPlayer
                    width={"100%"}
                    height={"100%"}
                    controls={true}
                    // playing={true}
                    url={selectedVideo}
                    // pip={true}
                  />
                  <button
                    className="p-1 ml-auto bg-transparent border-1 border text-white float-right text-3xl leading-none font-semibold outline-none focus:outline-none absolute -translate-y-8"
                    onClick={() => {
                      setmodalVideo(false);
                      setselectedVideo(false);
                    }}
                  >
                    <svg
                      className="w-6 h-6"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      )}
      <Footer />
    </>
  );
};
export default Video;
