import CardMenu from "../components/CardMenu";
import CarouselMenu from "../components/Carousel";
import Footer from "../components/Footer";
import NavBar from "../components/Navbar";
import quiz from "../images/3754170.jpg";
import tanya from "../images/Mesa de trabajo 1.svg";
import posterr from "../images/Artboard 2.svg";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import li1 from "../images/li1.webp";
import pilar1 from "../images/1digitalskill.png";
import pilar2 from "../images/2digitalculture.png";
import pilar3 from "../images/3digitalethics.png";
import pilar4 from "../images/4digitalsafety.png";
import li2 from "../images/li2.webp";
import li3 from "../images/li3.webp";
import li4 from "../images/li4.webp";
import newschar from "../images/newschar.jpg";
import vidchar from "../images/vidchar.jpg";

const Home = () => {
  const array1 = [1, 4, 9, 16];
  const navigation = useNavigate();
  return (
    <>
      <NavBar />
      <div className="relative bg-blue-1100 pb-72">
        <div className="w-full justify-center items-center mx-auto px-4 sm:px-6 flex flex-col gap-10">
          <div className="flex flex-col mt-14 text-center text-6xl font-extrabold">
            <p className="text-blue-1001 text-7xl md:text-8xl">SIPEDLI </p>{" "}
            <p>Sistem Peduli Digital Literasi</p>
          </div>
          <div className="text-center text-xl font-light">
            <p>Membuka wawasan Literasi Digitalmu !</p>
          </div>
          {/* card tanya  belajar upgrade lamar */}
          <div className="flex flex-col justify-center">
            <div className="flex flex-col md:flex-row md:space-x-24 justify-center">
              <Link to="/tanya">
                <CardMenu
                  name={"TANYA"}
                  image={tanya}
                  width={40}
                  navigate={"tanya"}
                />
              </Link>
              <Link to="/poster">
                <CardMenu
                  name={"POSTER"}
                  image={posterr}
                  width={52}
                  navigate={"poster"}
                />
              </Link>

              <Link to="/artikel">
                <CardMenu
                  name={"ARTIKEL"}
                  image={newschar}
                  width={52}
                  navigate={"artikel"}
                />
              </Link>
            </div>
            <div className="flex flex-col md:flex-row md:space-x-24 justify-center">
              <Link to="/video">
                <CardMenu
                  name={"VIDEO"}
                  image={vidchar}
                  width={52}
                  navigate={"video"}
                />
              </Link>
              <div
                className="cursor-pointer"
                onClick={() => {
                  if (localStorage.getItem("access_token")) {
                    navigation("/survey");
                  } else {
                    Swal.fire({
                      icon: "error",
                      title: "Tolong Login Terlebih Dulu",
                      // text: "Something went wrong!",
                      // footer: '<a href="">Why do I have this issue?</a>',
                    });
                    // navigation("/");
                  }
                }}
              >
                <CardMenu
                  name={"GAME"}
                  image={quiz}
                  width={40}
                  navigate={"survey"}
                />
              </div>
            </div>
            {/* <CardMenu name={"LAMAR"} /> */}
          </div>
        </div>
      </div>
      <div className="h-full bg-blue-1001 space-y-10 rounded-t-supermobile md:rounded-t-super flex-col justify-center items-center px-7 md:px-20 py-20 -translate-y-48">
        <div className="flex justify-center ">
          <h1 className="text-4xl text-center md:text-6xl font-bold text-white">
            4 Pilar Digital Literasi
          </h1>
        </div>
        <div className="flex flex-col md:flex-row justify-center gap-5 ">
          <div className=" text-white  w-full rounded-3xl flex-col items-center justify-center p-3 ">
            <div className=" rounded-2xl lg:rounded-3xl ">
              <div className="w-full h-full relative">
                <img className=" " src={pilar1} alt="" />
              </div>
            </div>
            <div className="p-5  space-y-4">
              <div>
                <h1 className="font-bold text-lg capitalize">Digital Skill</h1>
              </div>
              <div>
                <p className="text-left">
                  Kemampuan individual dalam mengetahui, memahami, dan
                  menggunakan perangkat keras dan piranti lunak TIK serta sistem
                  operasi digital dalam kehidupan sehari-hari
                </p>
              </div>
            </div>
          </div>
          <div className=" text-white  w-full rounded-3xl flex-col items-center justify-center p-3 ">
            <div className=" rounded-2xl lg:rounded-3xl ">
              <div className="w-full h-full relative">
                <img className=" " src={pilar2} alt="" />
              </div>
            </div>
            <div className="p-5  space-y-4">
              <div>
                <h1 className="font-bold text-lg capitalize">Digital Ethics</h1>
              </div>
              <div>
                <p className="text-left">
                  Kemampuan individual dalam menyadari, mencontohkan,
                  menyesuikan diri, merasionalkan, mempertimbangkan, dan
                  mengembangkan tata kelola etika digital (netiquette) dalam
                  kehidupan sehari-hari
                </p>
              </div>
            </div>
          </div>
          <div className=" text-white  w-full rounded-3xl flex-col items-center justify-center p-3 ">
            <div className=" rounded-2xl lg:rounded-3xl ">
              <div className="w-full h-full relative">
                <img className=" " src={pilar3} alt="" />
              </div>
            </div>
            <div className="p-5  space-y-4">
              <div>
                <h1 className="font-bold text-lg capitalize">
                  Digital Culture
                </h1>
              </div>
              <div>
                <p className="text-left">
                  Kemampuan individu dalam membaca, menguraikan, membiasakan,
                  memeriksa, dan membangun wawasan kebangsaan, nilai Pancasila
                  dan Bhinneka Tunggal Ika dalam kehidupan sehari-hari dan
                  digitalisasi kebudayaan melalui pemanfaatan TIK
                </p>
              </div>
            </div>
          </div>
          <div className=" text-white  w-full rounded-3xl flex-col items-center justify-center p-3 ">
            <div className=" rounded-2xl lg:rounded-3xl ">
              <div className="w-full h-full relative">
                <img className=" " src={pilar4} alt="" />
              </div>
            </div>
            <div className="p-5  space-y-4">
              <div>
                <h1 className="font-bold text-lg capitalize">Digital Safety</h1>
              </div>
              <div>
                <p className="text-left">
                  Kemampuan individu dalam mengenali, mempolakan, menerapkan,
                  menganalisis, menimbang, dan meningkatkan kesadaran
                  pelindungan data pribadi dan keamanan digital dalam kehidupan
                  sehari-hari digital dalam kehidupan sehari-hari
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-row mb-10 -translate-y-48">
        <CarouselMenu />
      </div>
      {/* <div className="bg-blue-1001 h-36"></div> */}
      <div className="">
        <Footer />
      </div>
    </>
  );
};

export default Home;
