import { useEffect, useState } from "react";
import axios from "axios";
import OpenAI from "openai";
import Lottie from "lottie-react";
import loading from "../images/loading.json";
const ChatGpt = () => {
  const [messages, setMessages] = useState([]);
  const [saveMessage, setsaveMessage] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [saveResponseChat, setsaveResponseChat] = useState([]);
  const [klik, setklik] = useState("");
  const [loading1, setloading1] = useState(false);
  console.log(saveMessage, "lolo");

  async function chatgpt2(data) {
    console.log(data, "dsa");
    setTimeout(() => {
      setloading1(true);
    }, 1000);
    const openai = new OpenAI({
      dangerouslyAllowBrowser: true,
      apiKey: "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", // defaults to process.env["OPENAI_API_KEY"]
    });
    const completion = await openai.chat.completions.create({
      model: "gpt-3.5-turbo",
      messages: data,
    });
    console.log(completion.choices[0].message);
    setsaveMessage([...data, completion.choices[0].message]);
    setloading1(false);
  }

  useEffect(() => {
    // chatgpt2();
    console.log("jalan");
  }, [klik]);
  // const gptfunc = async (data) => {
  //   const headers = {
  //     "Content-Type": "application/json",
  //     Authorization: "Bearer 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",
  //   };
  //   const datas = {
  //     inputs: "Q: " + data + " ?",
  //     parameters: {
  //       temperature: 1,
  //       min_length: 25,
  //       max_new_tokens: 50,
  //       return_full_text: true,
  //       do_sample: false,
  //       seed: 10,
  //       early_stopping: false,
  //       length_penalty: 0.0,
  //     },
  //     options: {
  //       use_cache: false,
  //       wait_for_model: false,
  //     },
  //   };
  //   const response = await axios.post(
  //     "https://api-inference.huggingface.co/models/bigscience/bloom",
  //     datas,
  //     { headers }
  //   );
  //   console.log(response, "dsadsa");
  //   // setresponChat(response.data[0].generated_text);
  //   setsaveResponseChat([...saveResponseChat, response.data[0].generated_text]);
  // };

  // useEffect(() => {
  //   async function example() {
  //     const api = new BingChat({
  //       cookie: process.env.BING_COOKIE ? process.env.BING_COOKIE : `_U=`,
  //     });

  //     const res = await api.sendMessage("Hello World!");
  //     console.log(res.text);
  //   }

  //   example();
  // }, []);
  // console.log(saveMessage, "sae");
  return (
    <>
      <div class="w-full h-screen bg-gray-50 flex flex-col" x-data="chat">
        <div class="bg-blue-1001 flex justify-center p-4">
          <span class="text-white  text-5xl font-light">Tanya Apa Aja</span>
        </div>

        <div class="w-full max-w-screen-lg flex-1 m-auto p-8 my-4 pb-20">
          <div class="flex flex-col">
            {saveMessage.map((e, index) => {
              return (
                <>
                  <div x-for="message in messages">
                    <div
                      class={`message  py-2 ${
                        e.role !== "user"
                          ? "bg-blue-1000 rounded-tl-3xl rounded-bl-3xl rounded-br-3xl ml-20"
                          : "bg-gray-500 rounded-tr-3xl rounded-bl-3xl rounded-br-3xl mr-20"
                      }  px-6 mb-4  `}
                    >
                      <span x-text="message.body"></span>
                      <div x-if="message.beingTyped" className="text-white">
                        {e.content}
                        {/* <span class="w-2.5 bg-gray-600 h-4 inline-block -mb-0.5 align-baseline blink"></span> */}
                      </div>
                    </div>
                  </div>
                  {/* {saveResponseChat[index] && (
                    <div x-if="showTyping">
                      <div class="message assistant rounded-lg py-2.5 px-6 mb-4 bg-blue-100 border-blue-100 self-start">
                        <div class="type-indicator">
                          {saveResponseChat && (
                            <>
                              {saveResponseChat[index].slice(
                                saveResponseChat[index].indexOf("?") + 1
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  )} */}
                </>
              );
            })}
          </div>
          {loading1 && (
            <Lottie animationData={loading} loop={true} className="w-24" />
          )}
          {/* <p>dsa</p> */}
        </div>
        <div class="fixed inset-x-0 bottom-0 bg-blue-100">
          <form class="max-w-screen-lg m-auto w-full p-4 flex space-x-4 justify-center items-center">
            <input
              id="message"
              type="text"
              value={messages}
              onChange={(e) => {
                setMessages(e.target.value);
              }}
              autocomplete="off"
              class="border rounded-md p-2 flex-1 border-blue-300 focus:ring-0 ring-blue-800"
              placeholder="Your message..."
            />
            <button
              onClick={(e) => {
                e.preventDefault();
                let obj = {
                  role: "user",
                  content: messages,
                };
                // console.log(obj);
                setklik(`${messages}`);
                setsaveMessage([...saveMessage, obj]);
                let res = [...saveMessage, obj];
                chatgpt2(res);
                setMessages("");
              }}
              class="bg-blue-1001 text-white px-4 py-2 rounded-md"
            >
              Send
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default ChatGpt;
