import { combineReducers } from "redux";
import jobReducer from "./job";
import loginRegisterReducer from "./LoginRegister";
import quizReducer from "./quiz";
import videoReducer from "./video";
import articleReducer from "./article";
const rootReducer = combineReducers({
  jobs: jobReducer,
  video: videoReducer,
  article: articleReducer,
  quiz: quizReducer,
  loginRegister: loginRegisterReducer,
});
// console.log(rootReducer);
export default rootReducer;
