import {
  faFacebookSquare,
  faInstagramSquare,
  faLinkedin,
  faLinkedinIn,
  faTiktok,
  faTwitterSquare,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const CardAnggota = ({ foto, nama, jabatan, ig, twitter, fb, linkedin }) => {
  return (
    <div className="md:w-1/2 bg-white p-6 rounded-md border shadow-lg flex flex-row space-x-12">
      <div className="">
        <img src={foto} className="w-32 h-36 object-cover rounded-md" alt="" />
      </div>
      <div className="space-y-2">
        <p className="font-bold text-lg">{nama}</p>
        <p className="font-semibold text-slate-400">{jabatan}</p>
        <hr className="" />
        <div className="flex flex-row space-x-4 pt-6">
          {ig && (
            <a href={ig}>
              <FontAwesomeIcon
                href=""
                className="scale-150 text-slate-700 hover:text-red-700 cursor-pointer"
                icon={faInstagramSquare}
              />
            </a>
          )}
          {twitter && (
            <a href={twitter}>
              <FontAwesomeIcon
                href=""
                className="scale-150 text-slate-700 hover:text-red-700 cursor-pointer "
                icon={faTwitterSquare}
              />
            </a>
          )}
          {fb && (
            <a href={fb}>
              <FontAwesomeIcon
                href=""
                className="scale-150 text-slate-700 hover:text-red-700 cursor-pointer"
                icon={faFacebookSquare}
              />
            </a>
          )}
          {linkedin && (
            <a href={linkedin}>
              <FontAwesomeIcon
                href=""
                className="scale-150 text-slate-700 hover:text-red-700 cursor-pointer"
                icon={faLinkedin}
              />
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default CardAnggota;
