import Aside from "../components/Aside";
import CardJob from "../components/CardJob";
import { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
// import NavBar from "../components/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { fetchJob, fetchPosterCategory } from "../store/action";
import NavBar from "../components/Navbar";
import Footer from "../components/Footer";
const LowonganKerja = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { jobs, category, loading, error } = useSelector((state) => state.jobs);
  const [selectImage, setselectImage] = useState("");
  const [modalImage, setmodalImage] = useState(false);
  const [selecetCategory, setselecetCategory] = useState("All");
  console.log(selecetCategory, "category seke");
  // const [datajob, setdatajob] = useState(jobs);

  const onClose = () => {
    setmodalImage(false);
    setselectImage("");
  };
  useEffect(() => {
    dispatch(fetchJob());
    dispatch(fetchPosterCategory());
  }, []);
  console.log(jobs, "ini job");
  // console.log(category, "cate");
  // if (loading) {
  //   return <h1>loading....</h1>;
  // }
  return (
    <>
      {modalImage && (
        <div
          onClick={onClose}
          className="fixed bg-black bg-opacity-50 backdrop-blur-sm w-full h-full z-50"
        >
          <div className="flex h-full justify-center items-center">
            <div className="w-96 md:scale-125">
              <img class=" object-cover rounded-lg" src={selectImage} alt="" />
            </div>
          </div>
        </div>
      )}
      <NavBar />
      <div className="relative w-full bg-blue-1001 md:px-40 md:p-10 pl-14 p-4 z-30">
        <p className="font-light text-5xl text-white">Poster</p>
      </div>
      <div className="bg-red"></div>
      <div className="relative mb-10">
        <div className=" relative z-30 w-full bg-white  px-28 py-5">
          <div className="flex flex-row justify-center gap-2 flex-wrap">
            <div
              onClick={() => {
                setselecetCategory("All");
              }}
              className="cursor-pointer hover:scale-105 border bg-black text-white p-2 px-4 rounded-md"
            >
              <p>All</p>
            </div>

            {category.length !== 0 &&
              category.map((e) => {
                return (
                  <div
                    onClick={() => {
                      setselecetCategory(e.id);
                    }}
                    className="cursor-pointer hover:scale-105 border bg-black text-white p-2 rounded-md"
                  >
                    <p>{e.name}</p>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="w-11/12 mx-auto px-4 sm:px-6 ">
          {!loading && (
            <CardJob
              setmodalImage={setmodalImage}
              setselectImage={setselectImage}
              jobs={jobs}
              selecetCategory={selecetCategory}
            />
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};
export default LowonganKerja;
