import {
  ERROR_MESSAGE,
  VIDEO_FETCH,
  LOADING,
  CATEGORY_VIDEO,
} from "../actionType";
// import { logger } from "./middlewares/logger";
const initialState = {
  loading: false,
  error: null,
  video: [],
  // user: [],
  // job: {},
  // company: {},
  // skills: [],
  category: [],
};

function videoReducer(state = initialState, action) {
  // console.log("anjay");
  // console.log(action.type);
  switch (action.type) {
    case ERROR_MESSAGE:
      return {
        ...state,
        error: action.payload,
      };
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case VIDEO_FETCH:
      return {
        ...state,
        video: action.payload,
      };
    case CATEGORY_VIDEO:
      return {
        ...state,
        category: action.payload,
      };
    default:
      return state;
  }
}
// const jobStore = createSt ore(videoReducer);
export default videoReducer;
