import { Outlet } from "react-router";
import NavBar from "../components/Navbar";

const MenuLowongan = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export default MenuLowongan;
