export const ERROR_MESSAGE = "error";
export const JOB_FETCH = "jobs/fetchsuccess";
export const VIDEO_FETCH = "video/fetchsuccess";
export const CATEGORY_VIDEO = "categoryvideo/fetchsuccess";
export const ARTICLES_FETCH = "article/fetchsuccess";
export const ARTICLE_DETAIL = "detailArticle/fetchsuccess";
export const CATEGORY_ARTICLES = "categoryvideo/fetchsuccess";
export const QUIZ_FETCH = "quiz/fetchsuccess";
export const SCORE_FETCH = "score/fetchsuccess";
export const JOB_DETAIL = "detailJob/fetchsuccess";
export const CATEGORY_POSTER = "category/fetchsuccess";

// export const LOADING_TRUE = "loading/true";
export const LOADING = "loading";

export const LOGIN = "login/loginform";
