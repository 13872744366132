import CardAnggota from "../components/CardAnggota";
import Footer from "../components/Footer";
import NavBar from "../components/Navbar";
import safira from "../images/safira.jpg";
import tia from "../images/tia.jpg";
import wina from "../images/wina.jpg";
import abid from "../images/abid.jpg";
import rifky from "../images/rifky.jpg";
const About = () => {
  return (
    <>
      <NavBar />
      <div className="relative w-full bg-blue-1001 md:px-40 md:p-10 pl-14 p-4 z-30">
        <p className="font-light text-5xl text-white">About</p>
      </div>
      <div className="p-2 md:p-20">
        <div className="flex flex-col md:flex-row px-2 md:px-14 mb-12 space-y-10 md:space-y-0 ">
          <div className="md:w-1/2">
            <p className="text-8xl font-medium text-center">
              APA ITU SIPEDLI ?
            </p>
          </div>
          <div className="md:w-1/2">
            <p className="text-justify text-lg">
              SIPEDLI adalah platform edukatif yang menyajikan pengetahuan
              tentang literasi digital serta berisi hasil penelitian dan survei
              mengenai literasi digital di Indonesia. Sebelum mengakses aplikasi
              ini, pengguna harus mendaftar terlebih dahulu untuk mengukur
              tingkat literasi digital mereka. Setelah mendaftar, pengguna dapat
              mencari solusi untuk masalah digital yang mereka alami dan menguji
              serta meningkatkan kemampuan literasi digital mereka. Di sisi
              lain, program pengabdian masyarakat di Kelurahan Sukarindik
              bertujuan mengatasi Digital Divide dengan memberikan pelatihan
              kepada warga di Kelurahan Bungursari, Kecamatan Bungursari, Kota
              Tasikmalaya. Program ini bertujuan meningkatkan kemampuan
              literasi, pengetahuan, dan kemampuan membedakan berita hoax dari
              fakta di kalangan masyarakat, sehingga mereka dapat lebih bijak
              dalam menghadapi informasi.
            </p>
          </div>
        </div>
        <div className="px-2 md:px-0 py-14">
          <p className="text-3xl font-semibold ">Anggota Tim Sipedli</p>
        </div>
        <div className="flex flex-col space-y-8">
          <div className="flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-10">
            <CardAnggota
              ig={"https://instagram.com/tiaramadani07?igshid=NGVhN2U2NjQ0Yg=="}
              linkedin={"https://www.linkedin.com/in/tiaramadani"}
              nama={"Tia Ramadani"}
              jabatan={"Ketua"}
              foto={tia}
            />{" "}
            <CardAnggota
              foto={wina}
              ig={"https://instagram.com/wina.wdywtii?igshid=YTQwZjQ0NmI0OA"}
              fb={"https://www.facebook.com/wina.widyawati.716"}
              nama={"Wina Widyawari"}
              jabatan={"Sekretaris "}
            />{" "}
          </div>
          <div className="flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-10">
            <CardAnggota
              foto={safira}
              linkedin={"https://www.linkedin.com/in/safira-salma-b41a0b278"}
              ig={
                "https://instagram.com/ssalmajihadia_?igshid=NGVhN2U2NjQ0Yg=="
              }
              nama={"Safira Salma Jihadia"}
              jabatan={"Bendahara "}
            />{" "}
            <CardAnggota
              nama={"Abid Abidin"}
              jabatan={"Anggota"}
              foto={abid}
              ig={"https://instagram.com/mangabid?igshid=OGQ5ZDc2ODk2ZA=="}
              fb={
                "https://www.facebook.com/profile.php?id=100093326932456&mibextid=ZbWKwL"
              }
            />{" "}
          </div>
          <div className="flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-10">
            <CardAnggota
              foto={rifky}
              ig={"https://instagram.com/rifky_rahayu?igshid=OGQ5ZDc2ODk2ZA=="}
              linkedin={"https://www.linkedin.com/in/rifky-rahayu-942452291"}
              nama={"Rifky Rahayu"}
              jabatan={"Anggota"}
            />{" "}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default About;
