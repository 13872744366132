import { useLocation, useNavigate, useParams } from "react-router-dom";
import Footer from "../components/Footer";
import NavBar from "../components/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchArticle } from "../store/action";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";
import innerText from "react-innertext";
const ArticleDetail = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = location;
  const { articles, article, category, loading, error } = useSelector(
    (state) => state.article
  );
  useEffect(() => {
    dispatch(fetchArticle());
  }, []);
  return (
    <>
      <NavBar />
      <div className="flex flex-col md:flex-row gap-1 mb-10">
        <div className="w-full md:w-3/4 space-y-3">
          {/* <FontAwesomeIcon
            size="2x"
            icon={faArrowCircleLeft}
            className="px-2  text-slate-400"
          /> */}
          <p className="text-2xl md:text-3xl font-bold px-2 md:px-20">
            {state.title}
          </p>
          <p className=" text-base font-normal px-2 md:px-20">
            {state.publishDate} / {state.source}
          </p>
          <img src={state.image_url} alt="" className="w-full px-2 md:px-20" />
          <div className="p-2 md:px-20">
            <div className="px-2 md:px-0 space-y-8">
              <p className="text-xl font-semibold ">{state.subTitle}</p>
              <p className="text-base font-normal text-justify">
                <div dangerouslySetInnerHTML={{ __html: state.body }} />
              </p>
            </div>
          </div>
        </div>
        <div className="mr-5 space-y-5 my-10 md:block hidden">
          {!loading &&
            articles.map((item, index) => {
              if (index === 5) {
                return;
              }
              return (
                <>
                  <div
                    onClick={() => {
                      navigation(`/artikelDetail/${item.id}`, {
                        state: item,
                      });
                    }}
                    className=" relative cursor-pointer flex  items-start flex-col w-72"
                  >
                    <img
                      src={item?.image_url}
                      alt=""
                      className="w-full h-40 object-cover"
                    />
                    <div className="flex flex-col justify-start text-left items-start  w-full py-1 space-y-2">
                      <p className="font-semibold text-base text-gray-600">
                        {item?.title}
                      </p>
                    </div>
                  </div>
                </>
              );
            })}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ArticleDetail;
