import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import logo from "../images/logo sipedli.png";
import logo1 from "../images/logo.png";

import FormRegisterLogin from "./FormRegisterLogin";
import { loginAdmin } from "../store/action";
import menuicon from "../images/menu_icon_152582.png";
const NavBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeMenu, setactiveMenu] = useState(
    localStorage.getItem("menu") ? localStorage.getItem("menu") : "home"
  );
  const [showModal, setShowModal] = useState(false);
  const [showModalRegister, setShowModalRegister] = useState(false);
  const [formLogin, setFormLogin] = useState({
    email: "",
    password: "",
  });
  const [zindexnav, setzindexnav] = useState("z-30");

  const [modalmobile, setmodalmobile] = useState(false);
  const handleChange = (e) => {
    // console.log("loon");
    const name = e.target.name;
    const value = e.target.value;

    setFormLogin({
      ...formLogin,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formLogin);
    dispatch(loginAdmin(formLogin)).then((e) => {
      setShowModal(false);
    });
  };
  const handleChangeMenu = () => {};

  return (
    <>
      <div className={`relative bg-white ${zindexnav}`}>
        <div className=" w-11/12 mx-auto px-4 sm:px-6 ">
          <div className="flex justify-between items-center border-b-2 border-gray-100 py-5 md:justify-start md:space-x-10">
            {/* kiri logo dan menu */}
            <div className="flex justify-start  space-x-10">
              <Link to="/" onClick={() => localStorage.setItem("menu", "home")}>
                <img className="w-36" src={logo1} alt="" />
              </Link>
              <nav className="hidden md:flex justify-center items-center ">
                <div>
                  <Link
                    to="/"
                    onClick={() => localStorage.setItem("menu", "home")}
                    className={`${
                      activeMenu === "home"
                        ? "border-b-4 text-blue-1001 font-bold"
                        : "font-bold text-gray-500 "
                    } border-black text-base font-medium mx-2 p-1  hover:text-gray-900`}
                  >
                    HOME
                  </Link>
                </div>
                {localStorage.getItem("username") && (
                  <div>
                    <Link
                      to="/survey"
                      onClick={() => localStorage.setItem("menu", "survey")}
                      className={`${
                        activeMenu === "survey"
                          ? "border-b-4 text-blue-1001 font-bold"
                          : "font-bold text-gray-500 "
                      } border-black text-base font-medium mx-2 p-1  hover:text-gray-900`}
                    >
                      Game
                    </Link>
                  </div>
                )}

                <div>
                  <Link
                    to="/poster"
                    onClick={() => localStorage.setItem("menu", "poster")}
                    className={`${
                      activeMenu === "poster"
                        ? "border-b-4 text-blue-1001 font-bold"
                        : "font-bold text-gray-500 "
                    } border-black text-base font-medium mx-2 p-1  hover:text-gray-900`}
                  >
                    Poster
                  </Link>
                </div>
                <div>
                  <Link
                    to="/artikel"
                    onClick={() => localStorage.setItem("menu", "artikel")}
                    className={`${
                      activeMenu === "artikel"
                        ? "border-b-4 text-blue-1001 font-bold"
                        : "font-bold text-gray-500 "
                    } border-black text-base font-medium mx-2 p-1  hover:text-gray-900`}
                  >
                    Artikel
                  </Link>
                </div>
                <div>
                  <Link
                    to="/video"
                    onClick={() => localStorage.setItem("menu", "video")}
                    className={`${
                      activeMenu === "video"
                        ? "border-b-4 text-blue-1001 font-bold"
                        : "font-bold text-gray-500 "
                    } border-black text-base font-medium mx-2 p-1  hover:text-gray-900`}
                  >
                    Video
                  </Link>
                </div>
                <div>
                  <Link
                    to="/tanya"
                    onClick={() => localStorage.setItem("menu", "tanya")}
                    className={`${
                      activeMenu === "tanya"
                        ? "border-b-4 text-blue-1001 font-bold"
                        : "font-bold text-gray-500 "
                    } border-black text-base font-medium mx-2 p-1  hover:text-gray-900`}
                  >
                    Tanya
                  </Link>
                </div>
                <div>
                  <Link
                    to="/about"
                    onClick={() => localStorage.setItem("menu", "about")}
                    className={`${
                      activeMenu === "about"
                        ? "border-b-4 text-blue-1001 font-bold"
                        : "font-bold text-gray-500 "
                    } border-black text-base font-medium mx-2 p-1  hover:text-gray-900`}
                  >
                    About
                  </Link>
                </div>
                {localStorage.getItem("username") === "safira" && (
                  <div>
                    <Link
                      to="/admin"
                      onClick={() => localStorage.setItem("menu", "admin")}
                      className={`${
                        activeMenu === "admin"
                          ? "border-b-4 text-blue-1001 font-bold"
                          : "font-bold text-gray-500 "
                      } border-black text-base font-medium mx-2 p-1  hover:text-gray-900`}
                    >
                      ADMIN
                    </Link>
                  </div>
                )}
                {/* <div>
                  <Link
                    to="/lowongan"
                    onClick={() => localStorage.setItem("menu", "hub")}
                    className={`${
                      activeMenu === "hub"
                        ? "border-b-4 text-blue-1001 font-bold"
                        : "font-bold text-gray-500 "
                    } border-black text-base font-medium mx-2 p-1  hover:text-gray-900`}
                  >
                    Tentang Kami
                  </Link>
                </div> */}
              </nav>
            </div>
            <nav className="md:hidden">
              <img
                src={menuicon}
                onClick={() => {
                  setmodalmobile(!modalmobile);
                  setzindexnav("z-50");
                }}
                className="w-8 h-8"
                alt=""
              />
            </nav>
            {modalmobile && (
              <div className="absolute bg-blue-1001 bg-opacity-80 rounded-tl-2xl rounded-bl-2xl rounded-br-2xl m-2 mt-28 mr-16 p-5 right-0 translate-y-32 space-y-3 z-50 backdrop-blur-sm">
                <div>
                  <Link
                    to="/"
                    onClick={() => localStorage.setItem("menu", "home")}
                    className=" font-bold text-white text-lg uppercase "
                  >
                    Home
                  </Link>
                </div>
                <div>
                  {localStorage.getItem("username") && (
                    <Link
                      to="/survey"
                      onClick={() => localStorage.setItem("menu", "survey")}
                      className=" font-bold text-white text-lg uppercase "
                    >
                      Game
                    </Link>
                  )}
                </div>
                <div>
                  <Link
                    to="/about"
                    onClick={() => localStorage.setItem("menu", "about")}
                    className=" font-bold text-white text-lg uppercase "
                  >
                    About
                  </Link>
                </div>
                <div>
                  <Link
                    to="/tanya"
                    onClick={() => localStorage.setItem("menu", "tanya")}
                    className=" font-bold text-white text-lg uppercase "
                  >
                    Tanya
                  </Link>
                </div>
                <div>
                  <Link
                    to="/poster"
                    onClick={() => localStorage.setItem("menu", "hub")}
                    className=" font-bold text-white text-lg uppercase "
                  >
                    Poster
                  </Link>
                </div>
                <div>
                  <Link
                    to="/artikel"
                    onClick={() => localStorage.setItem("menu", "artikel")}
                    className=" font-bold text-white text-lg uppercase "
                  >
                    Artikel
                  </Link>
                </div>
                <div>
                  <Link
                    to="/video"
                    onClick={() => localStorage.setItem("menu", "video")}
                    className=" font-bold text-white text-lg uppercase "
                  >
                    Video
                  </Link>
                </div>
                {!localStorage.getItem("access_token") && (
                  <div>
                    <div
                      // to="/poster"
                      onClick={() => {
                        setmodalmobile(false);
                        setShowModal(true);
                      }}
                      className=" font-bold text-white text-lg uppercase "
                    >
                      Login
                    </div>
                    <div>
                      <div
                        // to="/poster"
                        onClick={() => {
                          setmodalmobile(false);
                          setShowModalRegister(true);
                        }}
                        className=" font-bold text-white text-lg uppercase "
                      >
                        Register
                      </div>
                    </div>
                  </div>
                )}
                {localStorage.getItem("access_token") && (
                  <div>
                    <div
                      // to="/poster"
                      onClick={() => {
                        localStorage.clear();
                        navigate("/");
                      }}
                      className=" font-bold text-white text-lg uppercase "
                    >
                      Logout
                    </div>
                  </div>
                )}
                {localStorage.getItem("username") === "safira" && (
                  <div>
                    <Link
                      to="/admin"
                      onClick={() => localStorage.setItem("menu", "admin")}
                      className={`${
                        activeMenu === "admin"
                          ? " text-white font-bold"
                          : "font-bold text-white "
                      } border-black text-base font-medium `}
                    >
                      ADMIN PAGE
                    </Link>
                  </div>
                )}
                {/* <div>
                  <Link
                    to="/lowongan"
                    onClick={() => localStorage.setItem("menu", "hub")}
                    className=" font-bold text-white text-lg uppercase "
                  >
                    Tentang Kami
                  </Link>
                </div> */}
              </div>
            )}

            <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
              {!localStorage.getItem("access_token") && (
                <>
                  <button
                    type="button"
                    onClick={() => setShowModal(true)}
                    className="whitespace-nowrap text-base font-medium mx-2 p-1  hover:text-gray-900"
                  >
                    Log In
                  </button>

                  <button
                    onClick={() => setShowModalRegister(true)}
                    className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium mx-2 p-1 text-white bg-blue-1001 hover:bg-indigo-700"
                  >
                    Daftar
                  </button>
                </>
              )}
              {localStorage.getItem("access_token") && (
                <button
                  onClick={() => {
                    localStorage.clear();
                    navigate("/");
                  }}
                  className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium mx-2 p-1 text-white bg-blue-1001 hover:bg-indigo-700"
                >
                  Log Out
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative  my-6 mx-auto w-full md:w-5/12">
              {/*content*/}
              <div className="border-0  shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">Login</h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <svg
                      className="w-6 h-6"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
                {/*body*/}
                <form action="" onSubmit={handleSubmit}>
                  <div className="relative p-6 flex-auto">
                    <p className="font-semibold py-5">
                      Login to your Sipedli account to continue.
                    </p>
                    <div className="flex flex-col gap-4">
                      <input
                        type="text"
                        id="email"
                        name="email"
                        value={formLogin.email}
                        onChange={handleChange}
                        aria-describedby="helper-text-explanation"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Input your email"
                      />
                      <input
                        type="password"
                        id="password"
                        name="password"
                        value={formLogin.password}
                        onChange={handleChange}
                        aria-describedby="helper-text-explanation"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Input your Password"
                      />
                    </div>
                    <div className="py-4"></div>
                    <button
                      type="submit"
                      className="text-white bg-red-1000 border border-red-1000 focus:outline-none  font-medium text-sm px-5 py-2.5 mr-2 mb-2  flex flex-row gap-2 justify-center items-center w-full h-10 shadow-5xl hover:translate-x-1 hover:translate-y-1 hover:shadow-none ease-linear duration-100 "
                    >
                      MASUK
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

      {showModalRegister ? (
        // ini tempat register
        <FormRegisterLogin
          setShowModal={setShowModalRegister}
          formType={"FormLogin"}
        />
      ) : null}
    </>
  );
};

export default NavBar;
